import React, { useEffect, useReducer } from 'react';
import { graphql, PageProps } from 'gatsby';
import { Grid } from '@material-ui/core';

import { covid } from '@constants';
import { calculateGlobalTotals } from '@helpers';
import { TCovidSecurityIncident } from '@types';
import {
    CovidMap,
    MainWrapper,
    MobileScreenMessage,
    Header,
    Report,
    Legend,
    TotalIncidentsCount,
    Sidebar,
    TFilter,
    TSelectedCountry,
    Seo,
} from '@components';
import {
    StyledMapContainer,
    TCovidPageProperties,
    StyledReportContainer,
    TInitialState,
    TAction,
} from '.';

const INITIAL_STATE: TInitialState = {
    filter: {
        category: covid.filters.categoryOptions[0].value,
        fromDate: covid.filters.dates.from,
        toDate: covid.filters.dates.to,
    },
    incidents: undefined,
    selectedCountry: undefined,
    globalStatistics: undefined,
};

function reducer(state: TInitialState, action: TAction) {
    switch (action.type) {
        case 'setIncidents':
            return { ...state, incidents: action.value };
        case 'updateFilters':
            return { ...state, filter: action.value };
        case 'setSelectedCountry':
            return {
                ...state,
                selectedCountry: action.value,
            };
        case 'clearSelectedCountry':
            return { ...state, selectedCountry: undefined };
        case 'setGlobalStatistics':
            return { ...state, globalStatistics: action.value };
        default:
            return INITIAL_STATE;
    }
}

export default function Covid({ data }: PageProps<TCovidPageProperties>) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const { allSecurityIncidentsCsv, markdownRemark } = data;

    useEffect(() => {
        dispatch({
            type: 'setIncidents',
            value: allSecurityIncidentsCsv.nodes,
        });
    }, []);

    function handleFilterChange(filter: TFilter): void {
        dispatch({ type: 'updateFilters', value: filter });
    }

    function handleSettingSelectedCountry(
        selectedCountry: TSelectedCountry
    ): void {
        dispatch({ type: 'setSelectedCountry', value: selectedCountry });
    }

    function handleClearingSelectedCountry(): void {
        dispatch({ type: 'setSelectedCountry', value: undefined });
    }

    function handleSettingGlobalStatistics(
        filteredIncidents: TCovidSecurityIncident[]
    ): void {
        const globalStatistics = calculateGlobalTotals<TCovidSecurityIncident>(
            filteredIncidents
        );

        dispatch({ type: 'setGlobalStatistics', value: globalStatistics });
    }

    return (
        <StyledMapContainer>
            <Seo
                title={markdownRemark.frontmatter.title}
                description={markdownRemark.frontmatter.metaDescription}
            />
            <Header
                title={markdownRemark.frontmatter.heading}
                funderLogos={covid.funders.filter(
                    funder => funder.view === 'header'
                )}
            />
            <MainWrapper>
                <Grid item xs={2}>
                    <Sidebar
                        filter={state.filter}
                        setFilter={handleFilterChange}
                        categoryOptions={covid.filters.categoryOptions}
                        description={markdownRemark.frontmatter.sideBarCopy.replace(
                            'INSERT YEAR',
                            `${new Date().getFullYear()}`
                        )}
                        funderLogos={covid.funders.filter(
                            funder => funder.view === 'sidebar'
                        )}
                    />
                </Grid>
                <Grid item xs={10}>
                    <CovidMap
                        filter={state.filter}
                        incidents={state.incidents}
                        setSelectedCountry={handleSettingSelectedCountry}
                        clearSelectedCountry={handleClearingSelectedCountry}
                        setGlobalStatistics={handleSettingGlobalStatistics}
                        dataSourceAttribution={
                            markdownRemark.frontmatter.dataSourceAttribution
                        }
                    />
                    <TotalIncidentsCount
                        filter={state.filter}
                        filterCategory={covid.filters.categoryOptions.find(
                            option => option.value === state.filter.category
                        )}
                        globalStatistics={state.globalStatistics}
                    />
                    <Legend filter={state.filter} />
                </Grid>
            </MainWrapper>
            <MobileScreenMessage />
            {Boolean(state.selectedCountry) && (
                <StyledReportContainer>
                    <Report
                        filter={state.filter}
                        filterCategory={covid.filters.categoryOptions.find(
                            option => option.value === state.filter.category
                        )}
                        selectedCountry={state.selectedCountry}
                        clearSelectedCountry={handleClearingSelectedCountry}
                    />
                </StyledReportContainer>
            )}
        </StyledMapContainer>
    );
}

export const pageQuery = graphql`
    query CovidPageTemplate {
        markdownRemark(frontmatter: { path: { eq: "covid" } }) {
            frontmatter {
                path
                title
                heading
                metaDescription
                sideBarCopy
                dataSourceAttribution
            }
        }
        allSecurityIncidentsCsv {
            nodes {
                incidentNumber
                editedIncidentDescription
                isoDate
                isoCode
                latitude
                longitude
                geoPrecision
                perpetratorMap
                weaponUseMap
                allEventsAffectingHealthcare
                covidRelatedViolence
                healthMeasureMap
                conflictViolence
                totalHealthWorkerKilled
                totalHealthWorkerKidnapped
                totalHealthWorkerInjured
                totalHealthWorkerAssaulted
                totalHealthFacilitiesDestroyed
                totalHealthFacilitiesDamaged
                displayStatus
            }
        }
    }
`;
